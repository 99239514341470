











import Vue from 'vue';

import FriendList from './FriendList.vue'
import Player from './Player.vue'

export default Vue.extend({
  name: 'Header',
  components: {
      FriendList,
      Player,
  },
})
