
























































import Vue from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

Vue.component('cropper', Cropper);

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}

export default Vue.extend({
        name: 'ChangeAvatar',
        components: {
        },
        data(): unknown {
            return {
              isFound: false,
              valid: true,
              username: '',
              image: {
                name: '',
                src: '',
                type: '',
              },
            }
        },
        methods: {
          setNewInfos(dialog) {
            if (this.valid) {
              dialog.value = false;
              if (this.image.src) {
                const { canvas } = this.$refs.cropper.getResult();
                if (canvas) {
                  canvas.toBlob((blob) => {
                    const form = new FormData();
                    form.append('file', blob, this.image.name);
                    this.$http.put('/user/me/avatar', form).then(() => {
                      this.$http.get("/user/me/avatar", { responseType: 'arraybuffer' }).then(res => {
                        const avatar = "data:image/*" + ";base64," + Buffer.from(res.data).toString('base64')
                        this.$store.commit('setAvatar', avatar);
                      });
                    });
                  }, this.image.type);
                }
              }
              if (this.username != '') {
                this.$http.put('/user/me', {username: this.username}).then(() => {
                  this.$http.get('/user/me').then(response => {
                    this.$store.commit('setUser', response.data);
                  });
                });
              }
              this.username = '';
              this.$http.get('/user').then(response => {
                this.users = response.data;
              });
            }
          },

          loadImage(event) {
            const { files } = event.target;
            if (files && files[0]) {
              if (this.image.src) {
                URL.revokeObjectURL(this.image.src);
              }
              const blob = URL.createObjectURL(files[0]);
              const reader = new FileReader();
              reader.onload = (e => {
                this.image = {
                  name: files[0].name,
                  src: blob,
                  type: getMimeType(e.target.result, files[0].type),
                };
              });
              reader.readAsArrayBuffer(files[0]);
              this.valid = true;
            }
          },
        },

        created() {
          this.$http.get('/user').then(response => {
            this.users = response.data;
          });
        },
        computed: {
          users: {
            get() {
              return this.$store.getters.getListUsers;
            },
            set(users: unknown) {
              this.$store.commit('setListUsers', users);
            },
          },
          rules() {
            const rules = [];
            let existingName: string = null;
            let i = 0;

            if (this.username.length > 0) {
              existingName = this.users[i].username;
              while (this.username != existingName && i < this.users.length) {
                existingName = this.users[i++].username;
              }
              if (this.username === existingName) {
                const rule = `username already exist`;
                rules.push(rule);
              }
              let rule2 = v => (v && v.length <= 8) || 'must be less than 8 characters';
              rules.push(rule2);
              let rule3 = v => !(/\s/g.test(v)) || 'must not contain spaces';
              rules.push(rule3);
            }
            return rules;
          }
        }
    });
