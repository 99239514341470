





































import Vue from 'vue'

import ChangeAvatar from './settingsHeader/ChangeAvatar.vue'
import TwoFactor from './settingsHeader/TwoFactor.vue'
import Logout from './settingsHeader/Logout.vue'

Vue.component('ChangeAvatar', ChangeAvatar)
Vue.component('TwoFactor', TwoFactor)
Vue.component('Logout', Logout)

export default Vue.extend({
    name: 'Player',

    data() {
      return {
        items: [
        { title: 'Change Avatar' },
        { title: 'Two-Factor-Auth' },
        ],
        // user: [],
      }
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      avatar() {
        return this.$store.getters.getAvatar;
      },
      status() {
        if (this.user.status == 1)
          return 'blue';
        else if (this.user.status == 2)
          return 'orange';
        else if (this.user.status == 3)
          return '#49be25';
        else
          return 'grey';
      }
    }
  })
