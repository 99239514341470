














































import Vue from 'vue';

export default Vue.extend({
  name: 'TwoFactor',

  data(): unknown {
    return {
        qrCode: '',
        otp: '',
        loading: false,
        otpSetup: false,
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit('setUser', value);
      },
    },
  },
  methods: {
    validate () {
      this.loading = true
      this.$http.post('/auth/2fa', { "code": this.otp }).then(() => {
        this.$http.get('/auth/2fa/me').then((response) => {
          this.otpSetup = response.data;
          this.$toast.success('Two-factor authentication is enabled', {
            position: 'top-center',
            timeout: 3000,
          });
          this.loading = false;
          this.otp = '';
        });
      }).catch(() => {
        this.$toast.error('Invalid OTP code', {
          position: 'top-center',
          timeout: 3000,
        });
        this.loading = false;
        this.otp = '';
      });
    },
    disableOtp() {
      this.$http.delete('auth/2fa').then(() => {
        this.$http.get('auth/2fa/me').then((response) => {
          this.otpSetup = response.data;
          if (!this.otpSetup) {
            this.$toast.success('Two-factor authentication disabled', {
              position: 'top-center',
              timeout: 3000,
            });
            this.$http.get('/auth/2fa/qrcode').then(response => {
              this.qrCode = response.data;
            });
          }
        });
      });
    }
  },
  mounted() {
    // this.otpSetup = this.user.otp ? true : false;
    this.$http.get('/auth/2fa/me').then(response => {
      this.otpSetup = response.data;
      if (!this.otpSetup) {
        this.$http.get('/auth/2fa/qrcode').then(response => {
            this.qrCode = response.data;
        });
      }
    });
  }
});
