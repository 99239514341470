












































import Vue from "vue";
import FriendDisplay from './FriendDisplay.vue'
import UserDisplay from './UserDisplay.vue'

Vue.component('FriendDisplay', FriendDisplay);
Vue.component('UserDisplay', UserDisplay);

export default Vue.extend({
  data () {
    return {
      searchInput: null,
      dialog: false,
      added: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog=false;
    },
    rmFriend(rmId: number) {
      this.$http.put(`/user/me/follow/${rmId}`).then(() => {
        this.$http.get('/user/me').then(response => {
          this.me = response.data;
        });
      });
    },
    fetchUsers() {
      this.$http.get('/user').then(response => {
        this.listUsers = response.data;
      });
    }
  },
  mounted() {
    this.fetchUsers();
  },
  computed: {
    me: {
      get() {
        return this.$store.getters.getUser;
      },
      set(value) {
        this.$store.commit('setUser', value);
      }
    },
    listUsers: {
      get() {
        return this.$store.getters.getListUsers;
      },
      set(value) {
        this.$store.commit('setListUsers', value);
      }
    },
    filteredUsers(): unknown {
      let cleanUsers;
      cleanUsers = this.listUsers.filter((user) => {
        if (this.me.followed.indexOf(user.id) == -1 && user.id != this.me.id)
          return true;
        else
          return false;
      });
      cleanUsers = cleanUsers.filter((user) => {
        if (user.username == null)
          return false;
        return user.username.match(this.searchInput);
      })
      return cleanUsers;
    }
  }
})

