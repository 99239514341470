



















import Vue from 'vue';

export default Vue.extend({
    name: 'UserDisplay',
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    data(): unknown {
      return {
        loader: false,
        color: 'blue',
      }
    },
    computed: {
      me: {
        get() {
          return this.$store.getters.getUser;
        },
        set(user: unknown) {
          this.$store.commit('setUser', user);
        },
      },
      getStatusColor() {
        if (this.user.status == 1)
          return 'blue';
        else if (this.user.status == 2)
          return 'orange';
        else if (this.user.status == 3)
          return '#49be25';
        else
          return 'grey';
      },
    },
    methods: {
      isPlayerBlocked(id: number) {
        if (this.me.blocked.includes(id))
          return 'UNBLOCK';
        else
          return 'BLOCK';
      },
      block() {
        this.$http.put(`/user/me/block/${this.user.id}`).then(() => {
          this.$http.get('/user/me').then((res) => {
            this.me = res.data;
          });
        });
      },
      addStat() {
        this.loader = !this.loader;
        this.color = 'green';
        this.$http.put(`/user/me/follow/${this.user.id}`).then(() => {
          this.$http.get('/user/me').then(response => {
            this.me = response.data;
            this.loader = false;
          });
        });
        this.$emit('added');
      },

    },
    mounted() {
      if (this.me.blocked.indexOf(this.user.id) != -1) // si le user est bloque
      {
        this.disabledAdd = true;
      }
    }
})
